import React, { useEffect, useState } from 'react';
import axios from "axios";
import { TailSpin } from 'react-loader-spinner'
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { IoMdClose } from "react-icons/io";
import { ToastContainer, toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import totalcount from '../../assets/totalcount.png'
import { CiSearch } from "react-icons/ci";
import { FaPlus } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";

const ContentArea = () => {
  const [name, setName] = useState('')

  // Edit customer
  const [editPopUp, setEditPopUp] = useState(false)
  const [editUserDetails,setEditUserDetails] = useState({})

  const [deletePopUp, setDeletePopUp] = useState(false)

  const [emailId, setEmailId] = useState('')
  const [userId, setUserId] = useState('')
  const [userName, setUserName] = useState('')
  // const [companyName, setCompanyName] = useState('')
  
  const [showPopupResend, setShowPopupResend] = useState(false)
  const [showPopupAdd, setShowPopupAdd] = useState(false)

  const [details, setDetails] = useState([])
  const [loader, setLoader] = useState(true)
  const navigate = useNavigate();
  const token = Cookies.get('k9_jwt_token')
  const [usersCount, setUsersCount] = useState('')
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items per page

  // Calculate total pages
  const totalPages = Math.ceil(details.length / itemsPerPage);

  // Get current page data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = details.slice(indexOfFirstItem, indexOfLastItem);
  // setCurrentData(currentData)
  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleCancel = () => {
    setShowPopupResend(false);
  };

  const handleCancelAdd = () => {
    setShowPopupAdd(false);
  };

  const handleCancelDelete = () => {
    setDeletePopUp(false);
  };

// Edit customer
  const handleCancelEdit = () => {
    setEditPopUp(false);
  };

  const resendLink = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/users/re-send-link`,
        {
          userId: userId,
          emailId: emailId,
          name: userName,
          // companyName:companyName,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        },
      );
      if (response.data.success) {
        toast.success(response.data.message)
        setShowPopupResend(false)
      }
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("Error sending email")
      setShowPopupResend(false)
    }
  }

  const addCustomer = async (values) => {
    try {
      const modifiedValues = {
        ...values,
        name: `${values.firstName} ${values.lastName}`,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/users/user_registration`,
        modifiedValues,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        },
      );
      if (response.data.success) {
        toast.success(response.data.message)
        setShowPopupAdd(false)
        getUsers(name)
        getUsersCount()
      }
    } catch (error) {
      console.error("Error adding customer:", error);
      toast.error(error.response.data.message)
      setShowPopupAdd(false)
    }
  }

  const deleteCustomer = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/delete-user/${userId}`, // Pass userId in the URL
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.success) {
        toast.success(response.data.message);
        setDeletePopUp(false);
        getUsers(name);
        getUsersCount()

      }
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error(error.response?.data?.message || "Error deleting user");
      setDeletePopUp(false);
    }
  };
  
  // Edit customer
  
  const editCustomer = async (values) => {
    try {
      const modifiedValues = {
        ...values,
        name: `${values.firstName} ${values.lastName}`,
        userId:userId,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/users/edit_user`,
        modifiedValues,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        },
      );
      if (response.data.success) {
        toast.success(response.data.message)
        setEditPopUp(false)
        getUsers(name)
      }
    } catch (error) {
      console.error("Error editing customer:", error);
      toast.error(error.response.data.message)
      setEditPopUp(false)
    }
  }

  const PopupEdit = ({ onCancel, editCustomer,userDetails }) => {
    const [loaderEdit, setLoaderEdit] = useState(false)

    const fullName = userDetails.name; // Access the 'name' property
    const firstName = fullName.split(" ")[0];
    const lastName = fullName.split(" ")[1];

    const validationSchema = Yup.object({
      firstName: Yup.string()
        .matches(/^[A-Za-z ]*$/, "Only alphabets are allowed for name")
        .required('First Name is required'),

      lastName: Yup.string()
        .matches(/^[A-Za-z ]*$/, "Only alphabets are allowed for name")
        .required('Last Name is required'),

      phoneNumber: Yup.string()
        .matches(/^\d+$/, "Phone number must contain only digits")
        .min(10, 'Phone number must be at least 10 digits')
        .max(15, 'Phone number must be at most 15 digits')
        .required('Phone number is required'),

      email: Yup.string()
        .email('Invalid email format')
        .required('Email is required'),

      companyName:Yup.string()
        .required('Company Name is required')
    });

    const editCustomerHere = async (values) => {
      editCustomer(values)
    }

    return (
      <div className="fixed  inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
        <div className="bg-white p-4  rounded shadow-lg overflow-y-auto">
          <div className="flex justify-between items-center">
            <h1 className="font-semibold ml-14 text-lg">Edit Customer Details</h1>
            <button className="text-black py-2 text-[25px] px-4 font-medium rounded" onClick={onCancel}>
              <IoMdClose />
            </button>
          </div>
          <div className='p-4'>
            <Formik 
              initialValues={{ firstName: firstName, lastName: lastName, companyName:userDetails.companyName, phoneNumber: userDetails.phoneNumber, email: userDetails.email }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                setLoaderEdit(true)
                editCustomerHere(values)
              }}
            >
              {() => (
                <Form className='flex flex-col justify-center items-center '>
                  <div className='flex space-x-3'>
                  <div className='flex flex-col w-3/4 h-[80px] mb-4'>
                    <label htmlFor="firstName" className='text-left mb-1 text-gray-700'>First Name</label>
                    <Field type="text" className='border text-gray-600 rounded-sm p-1 px-2 mb-1' id="firstName" name="firstName" />
                    <ErrorMessage name="firstName" component="div" className="text-red-500 text-sm text-left ml-1" />
                  </div>

                  <div className='flex flex-col w-3/4 h-[80px] mb-4'>
                    <label htmlFor="lastName" className='text-left mb-1 text-gray-700'>Last Name</label>
                    <Field type="text" className='border text-gray-600 rounded-sm p-1 px-2 mb-1' id="lastName" name="lastName" />
                    <ErrorMessage name="lastName" component="div" className="text-red-500 text-sm text-left ml-1" />
                  </div>
                  </div>

                  <div className='flex space-x-3'>
                  <div className='flex flex-col w-3/4 h-[80px] mb-4'>
                    <label htmlFor="email" className='text-left mb-1 text-gray-700'>Email</label>
                    <Field type="email" className='border text-gray-600 rounded-sm p-1 px-2 mb-1' id="email" name="email" />
                    <ErrorMessage name="email" component="div" className="text-red-500 text-sm text-left ml-1" />
                  </div>

                  <div className='flex flex-col w-3/4 h-[100px] mb-4'>
                    <label htmlFor="companyName" className='text-left mb-1 text-gray-700'>Company Name</label>
                    <Field type="text" className='border text-gray-600 rounded-sm p-1 px-2 mb-1' id="companyName" name="companyName" />
                    <ErrorMessage name="companyName" component="div" className="text-red-500 text-sm text-left ml-1" />
                  </div>
                  </div>

                  <div className='flex flex-col w-3/4 h-[100px] mb-4'>
                    <label htmlFor="phoneNumber" className='text-left mb-1 text-gray-700'>Phone Number</label>
                    <Field type="text" className='border text-gray-600 rounded-sm p-1 px-2 mb-1' id="phoneNumber" name="phoneNumber" />
                    <ErrorMessage name="phoneNumber" component="div" className="text-red-500 text-sm text-left ml-1" />
                  </div>
                  

                  <button disabled={loaderEdit} className="bg-blue-600 p-2 mt-4 text-white rounded-lg" type="submit">
                    {loaderEdit ? <div className="flex justify-center items-center">
                      <div className="spinner" style={{
                        border: "2px solid rgba(255, 255, 255, 0.3)",
                        borderRadius: "50%",
                        borderTop: "2px solid #fff",
                        width: "20px",
                        height: "20px",
                        animation: "spin 1s linear infinite",
                      }} />

                    </div> : "Edit Customer"}</button>
                </Form>
                
              )}
            </Formik>
          </div>

        </div>
      </div>
    );
  };
  
  const PopupResend = ({ resendLink, emailId, onCancel, name }) => {
    const [loader, setLoaderbutton] = useState(false)

    const resendLinkHere = async () => {
      resendLink()
    }

    return (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
        <div className="bg-white p-8 pt-4 rounded shadow-lg overflow-y-auto">
          <div className="flex justify-end">
            <button className="text-black py-2 text-[25px] px-4 font-medium rounded" onClick={onCancel}>
              <IoMdClose />
            </button>
          </div>
          <div className=''>
            <h1 className='text-xl font-medium p-2 mb-5'>Do you want to send link to <br />{emailId} ? </h1>
            <button disabled={loader} onClick={() => {
              resendLinkHere(emailId, name)
              setLoaderbutton(true)
            }} className={`bg-[#000AFF] w-20 text-[#ffffff] p-1 m-1 mr-3 rounded-lg`}>
              {loader ? <div className="flex justify-center items-center">
                <div className="spinner" style={{
                  border: "2px solid rgba(255, 255, 255, 0.3)",
                  borderRadius: "50%",
                  borderTop: "2px solid #fff",
                  width: "20px",
                  height: "20px",
                  animation: "spin 1s linear infinite",
                  marginRight: "",
                }} />

              </div> : "Send"}</button>
            <button className={`bg-gray-500 w-20 text-[#ffffff] p-1 m-1 mr-3 rounded-lg`} onClick={onCancel}>Close</button>
          </div>
        </div>
      </div>
    );
  };

  // old PopAdd
  // const PopupAdd = ({ onCancel, addCustomer }) => {
  //   const [loaderAdd, setLoaderAdd] = useState(false)

  //   const validationSchema = Yup.object({
  //     firstName: Yup.string()
  //       .matches(/^[A-Za-z ]*$/, "Only alphabets are allowed for name")
  //       .required('First Name is required'),

  //     lastName: Yup.string()
  //       .matches(/^[A-Za-z ]*$/, "Only alphabets are allowed for name")
  //       .required('Last Name is required'),

  //     phoneNumber: Yup.string()
  //       .matches(/^\d+$/, "Phone number must contain only digits")
  //       .min(10, 'Phone number must be at least 10 digits')
  //       .max(15, 'Phone number must be at most 15 digits')
  //       .required('Phone number is required'),

  //     email: Yup.string()
  //       .email('Invalid email format')
  //       .required('Email is required')
  //   });

  //   const addCustomerHere = async (values) => {
  //     addCustomer(values)
  //   }

  //   return (
  //     <div className="fixed  inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
  //       <div className="bg-white p-4  rounded shadow-lg w-[400px] overflow-y-auto">
  //         <div className="flex justify-between items-center">
  //           <h1 className="font-semibold ml-14 text-lg">Create New Customer</h1>
  //           <button className="text-black py-2 text-[25px] px-4 font-medium rounded" onClick={onCancel}>
  //             <IoMdClose />
  //           </button>
  //         </div>
  //         <div className='p-4'>
  //           <Formik
  //             initialValues={{ firstName: '', lastName: '', phoneNumber: '', email: '' }}
  //             validationSchema={validationSchema}
  //             onSubmit={(values) => {
  //               setLoaderAdd(true)
  //               addCustomerHere(values)
  //             }}
  //           >
  //             {() => (
  //               <Form className='flex flex-col justify-center items-center '>
  //                 <div className='flex flex-col w-3/4 h-[80px] mb-4'>
  //                   <label htmlFor="firstName" className='text-left mb-1 text-gray-700'>First Name</label>
  //                   <Field type="text" className='border text-gray-600 rounded-sm p-1 px-2 mb-1' id="firstName" name="firstName" />
  //                   <ErrorMessage name="firstName" component="div" className="text-red-500 text-sm text-left ml-1" />
  //                 </div>

  //                 <div className='flex flex-col w-3/4 h-[80px] mb-4'>
  //                   <label htmlFor="lastName" className='text-left mb-1 text-gray-700'>Last Name</label>
  //                   <Field type="text" className='border text-gray-600 rounded-sm p-1 px-2 mb-1' id="lastName" name="lastName" />
  //                   <ErrorMessage name="lastName" component="div" className="text-red-500 text-sm text-left ml-1" />
  //                 </div>

                  

  //                 <div className='flex flex-col w-3/4 h-[80px] mb-4'>
  //                   <label htmlFor="email" className='text-left mb-1 text-gray-700'>Email</label>
  //                   <Field type="email" className='border text-gray-600 rounded-sm p-1 px-2 mb-1' id="email" name="email" />
  //                   <ErrorMessage name="email" component="div" className="text-red-500 text-sm text-left ml-1" />
  //                 </div>

  //                 <div className='flex flex-col w-3/4 h-[100px] mb-4'>
  //                   <label htmlFor="phoneNumber" className='text-left mb-1 text-gray-700'>Phone Number</label>
  //                   <Field type="text" className='border text-gray-600 rounded-sm p-1 px-2 mb-1' id="phoneNumber" name="phoneNumber" />
  //                   <ErrorMessage name="phoneNumber" component="div" className="text-red-500 text-sm text-left ml-1" />
  //                 </div>

  //                 <button disabled={loaderAdd} className="bg-blue-600 p-2 mt-4 text-white rounded-lg" type="submit">
  //                   {loaderAdd ? <div className="flex justify-center items-center">
  //                     <div className="spinner" style={{
  //                       border: "2px solid rgba(255, 255, 255, 0.3)",
  //                       borderRadius: "50%",
  //                       borderTop: "2px solid #fff",
  //                       width: "20px",
  //                       height: "20px",
  //                       animation: "spin 1s linear infinite",
  //                     }} />

  //                   </div> : "Add Customer"}</button>
  //               </Form>
  //             )}
  //           </Formik>
  //         </div>

  //       </div>
  //     </div>
  //   );
  // };

// new PopupAdd
  const PopupAdd = ({ onCancel, addCustomer }) => {
  const [loaderAdd, setLoaderAdd] = useState(false)

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .matches(/^[A-Za-z ]*$/, "Only alphabets are allowed for name")
      .required('First Name is required'),

    lastName: Yup.string()
      .matches(/^[A-Za-z ]*$/, "Only alphabets are allowed for name")
      .required('Last Name is required'),

    phoneNumber: Yup.string()
      .matches(/^\d+$/, "Phone number must contain only digits")
      .min(10, 'Phone number must be at least 10 digits')
      .max(15, 'Phone number must be at most 15 digits')
      .required('Phone number is required'),

    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),

    // companyName:Yup.string()
    //   .required('Company Name is required')
  });

  const addCustomerHere = async (values) => {
    addCustomer(values)
  }

  return (
    <div className="fixed  inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white p-4  rounded shadow-lg  overflow-y-auto">
        <div className="flex justify-between items-center">
          <h1 className="font-semibold ml-32 text-lg">Create New Customer</h1>
          <button className="text-black py-2 text-[25px] px-4 font-medium rounded" onClick={onCancel}>
            <IoMdClose />
          </button>
        </div>
        <div className='p-4'>
          <Formik
            initialValues={{ firstName: '', lastName: '', companyName:'', phoneNumber: '', email: '' }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              setLoaderAdd(true)
              addCustomerHere(values)
            }}
          >
            {() => (
              <Form className='flex flex-col justify-center items-center '>
                {/* <div className='flex space-x-3'>
                <div className='flex flex-col w-3/4 h-[100px] mb-4'>
                  <label htmlFor="firstName" className='text-left mb-1 text-gray-700'>First Name</label>
                  <Field type="text" className='border text-gray-600 rounded-sm p-1 px-2 mb-1' id="firstName" name="firstName" />
                  <ErrorMessage name="firstName" component="div" className="text-red-500 text-sm text-left ml-1" />
                </div>

                <div className='flex flex-col w-3/4 h-[100px] mb-4'>
                  <label htmlFor="lastName" className='text-left mb-1 text-gray-700'>Last Name</label>
                  <Field type="text" className='border text-gray-600 rounded-sm p-1 px-2 mb-1' id="lastName" name="lastName" />
                  <ErrorMessage name="lastName" component="div" className="text-red-500 text-sm text-left ml-1" />
                </div>
                </div>
               
               <div className='flex space-x-3'>
               <div className='flex flex-col w-3/4 h-[100px] mb-4'>
                  <label htmlFor="companyName" className='text-left mb-1 text-gray-700'>Company Name</label>
                  <Field type="text" className='border text-gray-600 rounded-sm p-1 px-2 mb-1' id="companyName" name="companyName" />
                  <ErrorMessage name="companyName" component="div" className="text-red-500 text-sm text-left ml-1" />
                </div>

                <div className='flex flex-col w-3/4 h-[100px] mb-4'>
                  <label htmlFor="email" className='text-left mb-1 text-gray-700'>Email</label>
                  <Field type="email" className='border text-gray-600 rounded-sm p-1 px-2 mb-1' id="email" name="email" />
                  <ErrorMessage name="email" component="div" className="text-red-500 text-sm text-left ml-1" />
                </div>
               </div>

                <div className='flex flex-col w-3/4 h-[100px] mb-4'>
                  <label htmlFor="phoneNumber" className='text-left mb-1 text-gray-700'>Phone Number</label>
                  <Field type="text" className='border text-gray-600 rounded-sm p-1 px-2 mb-1' id="phoneNumber" name="phoneNumber" />
                  <ErrorMessage name="phoneNumber" component="div" className="text-red-500 text-sm text-left ml-1" />
                </div> */}
                
                <div className='flex flex-col w-3/4 h-[100px] mb-4'>
                  <label htmlFor="firstName" className='text-left mb-1 text-gray-700'>First Name</label>
                  <Field type="text" className='border text-gray-600 rounded-sm p-1 px-2 mb-1' id="firstName" name="firstName" />
                  <ErrorMessage name="firstName" component="div" className="text-red-500 text-sm text-left ml-1" />
                </div>

                <div className='flex flex-col w-3/4 h-[100px] mb-4'>
                  <label htmlFor="lastName" className='text-left mb-1 text-gray-700'>Last Name</label>
                  <Field type="text" className='border text-gray-600 rounded-sm p-1 px-2 mb-1' id="lastName" name="lastName" />
                  <ErrorMessage name="lastName" component="div" className="text-red-500 text-sm text-left ml-1" />
                </div>
                
               
               {/* <div className='flex flex-col w-3/4 h-[100px] mb-4'>
                  <label htmlFor="companyName" className='text-left mb-1 text-gray-700'>Company Name</label>
                  <Field type="text" className='border text-gray-600 rounded-sm p-1 px-2 mb-1' id="companyName" name="companyName" />
                  <ErrorMessage name="companyName" component="div" className="text-red-500 text-sm text-left ml-1" />
                </div> */}

                <div className='flex flex-col w-3/4 h-[100px] mb-4'>
                  <label htmlFor="email" className='text-left mb-1 text-gray-700'>Email</label>
                  <Field type="email" className='border text-gray-600 rounded-sm p-1 px-2 mb-1' id="email" name="email" />
                  <ErrorMessage name="email" component="div" className="text-red-500 text-sm text-left ml-1" />
                </div>
               

                <div className='flex flex-col w-3/4 h-[100px] mb-4'>
                  <label htmlFor="phoneNumber" className='text-left mb-1 text-gray-700'>Phone Number</label>
                  <Field type="text" className='border text-gray-600 rounded-sm p-1 px-2 mb-1' id="phoneNumber" name="phoneNumber" />
                  <ErrorMessage name="phoneNumber" component="div" className="text-red-500 text-sm text-left ml-1" />
                </div>

                <button disabled={loaderAdd} className="bg-blue-600 p-2 mt-4 text-white rounded-lg" type="submit">
                  {loaderAdd ? <div className="flex justify-center items-center">
                    <div className="spinner" style={{
                      border: "2px solid rgba(255, 255, 255, 0.3)",
                      borderRadius: "50%",
                      borderTop: "2px solid #fff",
                      width: "20px",
                      height: "20px",
                      animation: "spin 1s linear infinite",
                    }} />

                  </div> : "Add Customer"}</button>
              </Form>
            )}
          </Formik>
        </div>

      </div>
    </div>
  );
};

  const PopupDelete = ({ onCancelDelete, deleteCustomer }) => {
    const [deleteLoader, setDeleteLoader] = useState(false)

    const cancelDeleteCustomer = async () => {
      onCancelDelete()
    }

    const deleteCustomerHere = async () => {
      setDeleteLoader(true)
      deleteCustomer()
    }

    return (
      <div className="fixed  inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
        <div className="bg-white p-4  rounded shadow-lg overflow-y-auto">
          <div className="flex justify-between items-center">
            <h1 className="font-semibold ml-14 text-lg">Delete Customer ?</h1>
            <button className="text-black py-2 text-[25px] px-4 font-medium rounded" onClick={cancelDeleteCustomer}>
              <IoMdClose />
            </button>
          </div>
          <div className='mt-4'>
           <h1><span className='font-semibold'>Name:</span> {userName}</h1>
          </div>
          <div className='p-3 flex justify-between mt-4'>
            <button className='border border-gray-700 p-2 rounded-md text-gray-700' onClick={cancelDeleteCustomer}>Cancel</button>
            <button className='bg-red-600 p-2 rounded-md text-[#ffffff]' onClick={deleteCustomerHere}>
            {deleteLoader ? <div className="flex justify-center items-center">
                      <div className="spinner" style={{
                        border: "2px solid rgba(255, 255, 255, 0.3)",
                        borderRadius: "50%",
                        borderTop: "2px solid #fff",
                        width: "20px",
                        height: "20px",
                        animation: "spin 1s linear infinite",
                      }} />

                    </div> : "Delete"}</button>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    getUsers(name)
    getUsersCount()
    // eslint-disable-next-line
  }, [name])

  const getUsers = async (name) => {
    try {
      setLoader(true)
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/all-users`,
        {
          params: { name },
          headers: {
            Authorization: `Bearer ${token}`
          }
        },
      );
      if (response.data.success) {
        setDetails(response.data.data)
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      toast.error("Error fetching users")
    } finally {
      setLoader(false);
    }

  }

  const getUsersCount = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/users-count`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        },
      );
      if (response.data.success) {
        setUsersCount(response.data.data)
      }
    } catch (error) {
      console.error("Error fetching users count:", error);
      toast.error("Error fetching users count")
    }

  }

  return (
    <div className="w-[80vw] flex-1 p-12 text-gray-700 h-[100vh] overflow-y-auto">
      <ToastContainer />
      <div className="flex justify-between items-center">
        <div className='border flex justify-center p-2 rounded-lg shadow-xl'>
          <div>
            <img src={totalcount} alt='count' className='w-14 mr-3' />
          </div>
          <div className=''>
            <h1 className=' font-semibold text-gray-500'>Total Customers</h1>
            <p className='font-semibold'>{usersCount}</p>
          </div>
        </div>

        <div className="ml-auto w-1/4 flex justify-end mb-3 mt-2">
          <button onClick={() => {
            Cookies.remove('k9_jwt_token');
            localStorage.removeItem('k9role')
            navigate('/');

          }} className="bg-[#000AFF] font-medium text-white px-4 text-sm py-2 rounded-md">Logout</button>

        </div>
      </div>
      <div className='flex justify-between mt-8 mb-8'>
        <button onClick={() => {
          setShowPopupAdd(true)
        }} className='bg-[#000AFF] flex justify-center items-center text-white text-sm font-normal px-3 py-2 ml-3 rounded-lg'>
          <span className='mr-2'><FaPlus /></span> Add Customer</button>
        <div className='border-2 rounded-md flex justify-start items-center w-64 pl-2'>
          <p className='text-gray-600'><CiSearch size={18} /></p>
          <input
            type="text"
            placeholder="Search"
            value={name}
            onChange={(e) => {
              setName(e.target.value)
            }}
            className="py-2 px-2 text-sm w-full font-normal focus:outline-none"
          />
        </div>
      </div>
      {loader ?
        <div className='h-[300px] flex justify-center items-center'>
          <TailSpin
            visible={true}
            height="80"
            width="80"
            color="#000AFF"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div> : <div className="">
          <div className="mt-5">
            <table className="min-w-full bg-white">
              <thead className='border text-sm text-left '>
                <tr>
                  <th className="  p-3">S.No</th>
                  <th className="  p-3">Customer Name</th>
                  {/* <th className="  p-3">Company Name</th> */}
                  <th className="  p-3">Email</th>
                  <th className="  p-3">Phone Number</th>
                  <th className="  px-2 text-center">Action</th>
                </tr>
              </thead>
              <tbody className=''>
                {currentData.map((each, index) => (
                  <tr key={each.id} className='text-gray-600 border text-sm text-left' >
                    <td className="p-2">{(index + 1) + ((currentPage - 1) * itemsPerPage)}</td>
                    <td className=" p-2">{each.name}</td>
                    {/* <td className=" p-2">{each.companyName}</td> */}
                    <td className=" p-2">{each.email}</td>
                    <td className=" p-2">{each.phoneNumber}</td>
                    <td className="p-2 flex justify-center">
                      {/* Edit customer */}

                      {/* <button onClick={() => {                      
                        setUserId(each.id)
                        setEditUserDetails({
                          name:each.name,
                          email:each.email,
                          phoneNumber:each.phoneNumber,
                          companyName:each.companyName
                        })
                        setEditPopUp(true)
                      }} className={`bg-[#000AFF] text-[#ffffff] text-md p-1 m-1 mr-3 pl-2 rounded-md`}>
                        <FaEdit /></button> */}
                      <button onClick={() => {
                        setEmailId(each.email)
                        setUserId(each.id)
                        setUserName(each.name)
                        // setCompanyName(each.companyName)
                        setShowPopupResend(true)
                      }} className={`bg-[#000AFF] w-20 text-[#ffffff] text-xs p-1 m-1 mr-3 rounded-md`}>Send Link</button>
                      <button onClick={() => {
                        navigate(`/accounts/${each.id}`)
                      }}
                        disabled={!each.verified} className={`bg-[#ffffff]  p-1 m-1 w-20 rounded-md text-xs border ${each.verified ? " border-[#000AFF] text-[#000AFF]" : "text-gray-500 border-gray-400 cursor-not-allowed"}`}>View</button>
                      <button onClick={() => {
                         setEmailId(each.email)
                         setUserId(each.id)
                         setUserName(each.name)
                        setDeletePopUp(true)
                      }} className={`bg-[#000AFF] text-[#ffffff] text-md p-1 m-1 mr-3 rounded-md`}>
                        <MdDelete/></button>
                      </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="flex justify-between items-center border-l border-r border-b p-2">
              <button
                className={`rounded-lg bg-[#ffffff] border border-gray-400 text-gray-800 m-1 p-1 text-sm ${currentPage === 1 ? 'cursor-not-allowed' : ' focus:outline-none focus:shadow-outline'}`}
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span className='text-gray-800 text-sm'>Page {currentPage} of {totalPages}</span>
              <button
                className={`rounded-lg bg-[#ffffff] border border-gray-400 text-sm text-gray-800 m-1 p-1 ${currentPage === totalPages ? 'cursor-not-allowed' : 'focus:outline-none focus:shadow-outline'}`}
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>

        </div>}

      {deletePopUp && <PopupDelete onCancelDelete={handleCancelDelete} deleteCustomer={deleteCustomer}/>}

{/* Edit Customer */}
      {editPopUp && <PopupEdit onCancel={handleCancelEdit} editCustomer={editCustomer} userDetails={editUserDetails}/>}

      {showPopupResend &&
        <PopupResend
          resendLink={resendLink}
          onCancel={handleCancel}
          emailId={emailId}
          userId={userId}
          name={userName}
        />}
      {showPopupAdd &&
        <PopupAdd
          addCustomer={addCustomer}
          onCancel={handleCancelAdd}
        />}
    </div>
  );
};

export default ContentArea;
