import React from 'react'
import notFound from '../assets/notFound.jpg'
const NotFound = () => {
  return (
    <div className='bg-[#001f3f] h-[100vh] flex flex-col justify-center items-center p-5 '>
      <img src={notFound} alt="not-found" className='h-[500px] w-auto rounded-lg' />
    </div>
  )
}
export default NotFound

