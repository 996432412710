import React from 'react';
import Sidebar from '../Sidebar';
import TransactionsContentArea from './TransactionContentArea';

const Transactions = () => {
  return (
    <div className="flex">
      <Sidebar />
      <TransactionsContentArea />
    </div>
  );
};

export default Transactions;
