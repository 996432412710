import './App.css';
import Cookies from 'js-cookie';

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import NotFound from './components/NotFound';
// import PaymentRequest from './components/Payment-request';
import Home from './components/Home';
import Dashboard from './components/Dashboard/Dashboard';
// import PaymentRequestForm from './components/PaymentRequestFormAdmin';
import UserLinkPage from './components/UserLinkPage/UserLinkPage';
import BanksAndAccounts from './components/BanksAndAccounts/BanksAndAccounts';
import Transactions from './components/Transactions/Transactions';
import UserAuthLink from './components/UserLinkPage/UserAccountReAuth'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import EditEmail from './components/EditEmail/EditEmail';

const RedirectToDashboard = () => {
  const navigate = useNavigate();
  let jwt_token = Cookies.get('k9_jwt_token');

  useEffect(() => {
    // If the user is authenticated, redirect them to the dashboard
    if (jwt_token) {
      navigate('/dashboard');
    }
  }, [jwt_token, navigate]);

  return <Navigate to="/" />;
};

const PrivateRoutesSuperAdmin = ({ requiredRole }) => {
  let role = localStorage.getItem("k9role");
  let jwt_token = Cookies.get('k9_jwt_token');

  if (!jwt_token || (requiredRole && role !== requiredRole)) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};


function App() {
  return (
    <Router>
      <div className="App"> 
        <Routes>
          <Route exact path="/" element={<Home/>}/>

          <Route element={<PrivateRoutesSuperAdmin requiredRole="ADMIN" />}>
          <Route exact path="/dashboard" element={<Dashboard/>}/>
          <Route exact path="/accounts/:userId" element={<BanksAndAccounts/>}/>
          <Route exact path="/account-transactions/:accountId" element={<Transactions/>}/>
          <Route exact path="/edit-email" element={<EditEmail/>}/>

          </Route>

          <Route exact path="/user/:userId" element={<UserLinkPage/>}/>
          <Route exact path="/user/reauthrorize/:accountId" element={<UserAuthLink/>}/>


          <Route path="*" element={<RedirectToDashboard/>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
