import React from 'react';
import k9logo2 from './../assets/K9logo2 .png'
import { HiOutlineDotsVertical } from "react-icons/hi";
import { RxDashboard } from "react-icons/rx";
import { FaRegEdit } from "react-icons/fa";
import { TbMailCog } from "react-icons/tb";

const Sidebar = () => {
  return (
    <div className="w-[20vw] h-screen bg-gray-900 text-white flex flex-col">
      <div className="p-6 flex justify-center">
        <a href='/dashboard'>
          <img src={k9logo2} alt='logo' className='w-20' />
        </a>
        {/* <a href='/dashboard'><h1 className="text-2xl font-bold">K9BookKeeping</h1></a> */}
        {/* <p className="text-sm">Name</p> */}
      </div>
      <nav className="mt-10 flex-1">
        <a href="/dashboard" className="flex justify-between items-center p-2 text-gray-400 hover:bg-gray-700 hover:text-white">
          <div className="flex justify-center items-ceter ml-2 text-lg">
            <p className='p-1 mr-2'><RxDashboard /></p>
            <h1>Dashboard</h1>
          </div>
          <p className="mr-4"><HiOutlineDotsVertical /></p>
        </a>
        {/* <a href="/edit-email" className="flex items-center p-2 text-gray-400 hover:bg-gray-700 hover:text-white mt-2">
        <div className="flex justify-center items-ceter ml-2 text-lg">
            <p className='p-1 mr-2'><TbMailCog /></p>
            <h1>Edit Email</h1>
          </div>
        </a> */}
      </nav>
    </div>
  );
};

export default Sidebar;
