import React from 'react';
import Sidebar from '../Sidebar';
import BanksAndAccountsContentArea from './BanksAndAccountsContentArea';

const BanksAndAccounts = () => {
  return (
    <div className="flex">
      <Sidebar />
      <BanksAndAccountsContentArea />
    </div>
  );
};

export default BanksAndAccounts;
