import React from 'react';
import Sidebar from '../Sidebar';
import EditEmailContent from './EditEmailContent';

const EditEmail = () => {
  return (
    <div className="flex">
      <Sidebar />
      <EditEmailContent />
    </div>
  );
};

export default EditEmail;
