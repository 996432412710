import { useState, useEffect, useRef } from "react"
import Cookies from 'js-cookie';
import axios from "axios";
import { TailSpin } from 'react-loader-spinner'
import { useParams } from 'react-router-dom';
import Nodata from '../../assets/nodata.jpg'
import { toast, ToastContainer } from "react-toastify";
import { RiDownloadLine } from "react-icons/ri";
import { IoClose } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import K9PdfLogo from '../../assets/K9PdfLogo.png'
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

//Report is disabled


const TransactionsContentArea = () => {
  const token = Cookies.get('k9_jwt_token')
  const dropdownRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [entries, setEntries] = useState(15)
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [userLoading, setUserLoading] = useState(true)
  const [accountLoader, setAccountLoader] = useState(true)
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const navigate = useNavigate();


  const [transactions, setTransactions] = useState([]);
  const [noDateTransactionDates, setNoDatesTransactionDates] = useState('')
  const [historyBalanceDates, setHistoryBalanceDates] = useState('')

  const [openCloseDetails, setOpenCloseDetails] = useState('')
  const [isOpen, setIsOpen] = useState(false);
  const { accountId } = useParams();
  const moment = require('moment');
  const [accountDetails, setAccountDetails] = useState('')
  const totalPages = Math.ceil(transactions.length / entries);
  const startIndex = parseInt((currentPage - 1) * entries);
  const currentItems = transactions.slice(startIndex, startIndex + entries);

  useEffect(() => {
    if (transactions.length !== 0) {
      getHistoryBalances(transactions)
    }
  }, [transactions])

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const getHistoryBalances = async (data) => {
    try {
      // Extract unique dates from transactions
      const uniqueDates = [
        ...new Set(
          transactions.map(data => moment.utc(data.date).format('YYYY-MM-DD'))
        )
      ];
      // console.log(uniqueDates)

      // Prepare the payload with unique dates and accountId
      const payload = {
        accountId,
        uniqueDates,
      };

      // Send the payload to the server
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/transactions-history-balance`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );

      // console.log(response)
      if (response.data.success) {
        setHistoryBalanceDates(response.data.data)
      } else {
        console.error("Failed to send unique dates.");
      }
    } catch (error) {
      console.error("Error sending unique dates", error);
      toast.error("Error sending unique dates");
    }

  }

  const getTransactions = async (accountId) => {
    try {
      setUserLoading(true);

      // Define params object for filtering transactions
      const params = {
        // categorySearch: categorySearch, // Apply search only if it's set
      };
      if (fromDate) params.fromDate = moment.utc(fromDate, 'YYYY-MM-DD').startOf('day').format('YYYY-MM-DD HH:mm:ss');
      if (toDate) params.toDate = moment.utc(toDate, 'YYYY-MM-DD').endOf('day').format('YYYY-MM-DD HH:mm:ss');
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/all-transactions/${accountId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params,
        }
      );

      if (response.data.success) {
        setTransactions(response.data.data);
        const length = response?.data?.data?.length
        const toDateNoData = response?.data?.data[0]?.date
        const fromDateNoData = response?.data?.data[length - 1]?.date
        setNoDatesTransactionDates({ toDateNoData: toDateNoData, fromDateNoData: fromDateNoData })
      }
    } catch (error) {
      console.error("Error fetching Transaction list", error);
      toast.error("Error fetching Transaction list");
    } finally {
      setUserLoading(false);
    }
  };

  const handleClear = () => {
    setFromDate('');
    setToDate('');; // Fetch without filters
    setOpenCloseDetails('')
  };

  const getOpenANdCloseBalance = async () => {
    try {
      // Define params object for filtering transactions
      const params = {
        // categorySearch: categorySearch, // Apply search only if it's set
      };

      // Add fromDate and toDate to params if they are set
      // if (fromDate) params.fromDate = fromDate;
      
      if (fromDate !== '') params.fromDate = new Date(new Date(fromDate).setDate(new Date(fromDate).getDate() - 1)).toISOString().split('T')[0];
      if (toDate !== '') params.toDate = toDate;
      // console.log(params)
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/open-close-details/${accountId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params,
        }
      );
      // console.log(response.data)
      if (response.data.success) {
        setOpenCloseDetails(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching Open And Close Details list", error);
      toast.error("Error fetching  Open And Close Details list");
    }
  }

  // Listen for changes in the date or search filter
  useEffect(() => {
    getTransactions(accountId);
    if (fromDate !== '' || toDate !== '') {
      getOpenANdCloseBalance()
    }

    // if (fromDate !== '' && toDate !== '') {
    //   getOpenANdCloseBalance()
    // }
    // eslint-disable-next-line
  }, [fromDate, toDate]);


  const handleChangeEntries = (event) => {
    setEntries(parseInt(event.target.value));
    setCurrentPage(1);
  };

  const getAccount = async () => {
    try {
      setAccountLoader(true)
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/users/get-account-details`, { accountId },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        },
      );
      if (response.data.success) {
        setAccountDetails(response.data.data)
      }
    } catch (error) {
      console.error("Error fetching account details:", error);
      toast.error("Error fetching account details");
    } finally {
      setAccountLoader(false);
    }
  }

  useEffect(() => {
    getAccount(accountId)
    // eslint-disable-next-line
  }, [accountId]);

  const toggleDropdown = () => {
    setIsOpen(prevState => !prevState);
  };

  // const openModal = () => {
  //   setIsModalOpen(true);
  // };

  // const closeModal = () => {
  //   setIsModalOpen(false);
  // };


  // const ReportPopup = ({ isOpen, onClose, fromDate, toDate, accountId }) => {
  //   const [reportData, setReportData] = useState([]);
  //   const [selectedYearData, setSelectedYearData] = useState(null);
  //   const [activeYear, setActiveYear] = useState(null);

  //   // Fetch report data from the backend when the component is opened
  //   useEffect(() => {
  //     if (isOpen && fromDate && toDate && accountId) {
  //       fetchReportData(fromDate, toDate, accountId);
  //     }
  //   }, [isOpen, fromDate, toDate, accountId]);

  //   const fetchReportData = async (fromDate, toDate, accountId) => {
  //     try {
  //       const formattedFromDate = moment(fromDate).format("MM/DD/YYYY");
  //       const formattedToDate = moment(toDate).format("MM/DD/YYYY");

  //       const response = await axios.get(
  //         `${process.env.REACT_APP_API_URL}/api/users/report`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,  // Include the Authorization header
  //           },
  //           params: {
  //             fromDate: formattedFromDate,
  //             toDate: formattedToDate,
  //             accountId
  //           },
  //         }
  //       );

  //       const data = response.data;

  //       if (data.success) {
  //         setReportData(data.data);

  //         // Automatically select the first year by default
  //         if (data.data.length > 0) {
  //           const firstYearData = data.data[0];
  //           setActiveYear(firstYearData.year);
  //           setSelectedYearData(firstYearData);
  //         }
  //       } else {
  //         console.error("Failed to fetch data");
  //       }
  //     } catch (error) {
  //       console.error("Error fetching the report data:", error);
  //     }
  //   };

  //   const handleYearClick = (year) => {
  //     const yearData = reportData.find((report) => report.year === year);
  //     setSelectedYearData(yearData);
  //     setActiveYear(year);
  //   };

  //   if (!isOpen) return null;

  //   return (
  //     <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
  //       <div className="bg-white p-8 rounded-lg shadow-lg w-3/4 max-w-2xl">
  //         <h2 className="text-left text-lg font-bold">Transaction Report</h2>
  //         <hr className="my-4" />

  //         {/* Year List */}
  //         <div className="flex mb-4">
  //           {reportData.map((report) => (
  //             <button
  //               key={report.year}
  //               className={`px-4 py-2 rounded-lg border-2 text-black hover:bg-blue-600 hover:text-white
  //                 ${activeYear === report.year ? 'bg-blue-600  text-white' : 'border-2 text-black border-gray-200 rounded-lg'}`}
  //               onClick={() => handleYearClick(report.year)}
  //             >
  //               {report.year}
  //             </button>
  //           ))}
  //         </div>

  //         {/* Table with transactions */}
  //         {selectedYearData && (
  //           <>
  //             <table className="w-full mt-4 border-collapse border">
  //               <thead>
  //                 <tr className="text-left text-sm">
  //                   <th className="border px-4 py-2">S.No</th>
  //                   <th className="border px-4 py-2">Month</th>
  //                   <th className="border px-4 py-2">No. of Transactions</th>
  //                   <th className="border px-4 py-2">Pricing Plan ($)</th> {/* New column for Amount */}
  //                 </tr>
  //               </thead>
  //               <tbody>
  //                 {selectedYearData.months.map((monthData, index) => (
  //                   <tr key={monthData.month} className="text-left text-sm">
  //                     <td className="border px-4 py-2">{index + 1}</td>
  //                     <td className="border px-4 py-2">{monthData.month}</td>
  //                     <td className="border px-4 py-2">{monthData.count}</td>
  //                     <td className="border px-4 py-2">${monthData.amount.toFixed(2)}</td> {/* Display amount */}
  //                   </tr>
  //                 ))}
  //                 {/* Total Count and Amount Row */}
  //                 <tr className="text-left text-sm">
  //                   <td className="border px-4 py-2 font-bold" colSpan="2">Total</td>
  //                   <td className="border px-4 py-2 font-bold">{selectedYearData.totalCount}</td>
  //                   <td className="border px-4 py-2 font-bold">${selectedYearData.totalAmount.toFixed(2)}</td>
  //                 </tr>
  //               </tbody>
  //             </table>
  //           </>
  //         )}

  //         {/* Close button */}
  //         <div className="flex justify-center mt-6">
  //           <button
  //             className="bg-[#000AFF] font-medium text-white px-4 text-sm py-2 rounded-md"
  //             onClick={onClose}
  //           >
  //             Close
  //           </button>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };




  const downloadCSV = () => {
    // Prepare transactions data: only include date, name (as description), and amount
    let formattedTransactions;
    if (accountDetails.type === 'depository') {
      formattedTransactions = transactions.map(({ date, name, amount, pending }) => ({
        post_date: `${moment.utc(date).format('YYYY-MM-DD')}`, // Format date as text by adding a single quote
        suggest_date: date
          ? moment.utc(date).subtract(1, 'day').format('YYYY-MM-DD')
          : "N/A",
        description: name, // Replace 'name' with 'description'
        amount,
        pending,
      }));
    } else if (accountDetails.type === 'credit') {
      formattedTransactions = transactions.map(({ date, name, amount, pending }) => ({
        post_date: `${moment.utc(date).format('YYYY-MM-DD')}`, // Format date as text by adding a single quote
        suggest_date: date
          ? moment.utc(date).format('YYYY-MM-DD')
          : "N/A",
        description: name, // Replace 'name' with 'description'
        amount,
        pending,
      }));
    }
    // const formattedTransactionss = transactions.map(({ date, name, amount, pending }) => ({
    //   date: `${moment.utc(date).format('YYYY-MM-DD')}`, // Format date as text by adding a single quote
    //   description: name, // Replace 'name' with 'description'
    //   amount,
    //   pending,
    // }));

    // Convert the formatted data to CSV
    const csv = Papa.unparse(formattedTransactions);

    // Create a blob from the CSV string
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    // Create a link element and click it to start the download
    const a = document.createElement('a');
    a.href = url;
    a.download = 'transactions.csv';
    a.click();

    // Revoke the object URL to free up resources
    URL.revokeObjectURL(url);
  };

  const downloadExcel = () => {
    // Convert categoryName arrays to strings and exclude createdAt and updatedAt
    let formattedTransactions;
    if (accountDetails.type === 'depository') {
      formattedTransactions = transactions.map(({ date, name, amount, pending }) => ({
        post_date: `${moment.utc(date).format('YYYY-MM-DD')}`, // Format date as text by adding a single quote
        suggest_date: date
          ? moment.utc(date).subtract(1, 'day').format('YYYY-MM-DD')
          : "N/A",
        description: name, // Replace 'name' with 'description'
        amount,
        pending,
      }));
    } else if (accountDetails.type === 'credit') {
      formattedTransactions = transactions.map(({ date, name, amount, pending }) => ({
        post_date: `${moment.utc(date).format('YYYY-MM-DD')}`, // Format date as text by adding a single quote
        suggest_date: date
          ? moment.utc(date).format('YYYY-MM-DD')
          : "N/A",
        description: name, // Replace 'name' with 'description'
        amount,
        pending,
      }));
    }
    // const formattedTransactionss = transactions.map(({ date, name, amount, pending }) => ({
    //   date: moment.utc(date).format('YYYY-MM-DD'), // Format date using Moment.js
    //   description: name, // Map 'name' from the database to 'description' in the Excel export
    //   amount,
    //   pending,
    // }));

    const ws = XLSX.utils.json_to_sheet(formattedTransactions);
    const wb = XLSX.utils.book_new();

    // Set column widths 
    const columnWidths = [
      { wpx: 100 }, // Set width for date
      { wpx: 100 }, // Set width for authorised_date
      { wpx: 150 }, // Set width for description
      { wpx: 100 }, // Set width for amount
    ];

    ws['!cols'] = columnWidths; // Apply column widths to the worksheet

    XLSX.utils.book_append_sheet(wb, ws, 'Transactions');
    XLSX.writeFile(wb, 'transactions.xlsx');
  };

  const downloadPDF = () => {
    const doc = new jsPDF("landscape");

    const img = new Image();
    img.src = K9PdfLogo;

    // Add the logo to the PDF
    doc.addImage(img, 'PNG', 180, 13, 12, 10);

    // Title for the document
    doc.setTextColor(41, 128, 185);
    doc.setFontSize(18);
    doc.text('Transactions Report', doc.internal.pageSize.width / 2, 20, null, null, 'center');

    // PART 1: Account Overview
    doc.setTextColor(41, 128, 185);
    doc.setFontSize(14);
    doc.setDrawColor(41, 128, 185);
    doc.setLineWidth(0.5);
    doc.rect(14, 30, doc.internal.pageSize.width - 28, 40);
    doc.setFillColor(235, 245, 251);
    doc.rect(14, 30, doc.internal.pageSize.width - 28, 10, 'F');
    doc.text('Account Overview', 16, 38);

    doc.setFontSize(11);
    doc.setTextColor(0, 0, 0);
    doc.text(`Institution Name:  ${accountDetails.institution.insName || 'N/A'}`, 16, 48);
    doc.text(`Account Name:     ${accountDetails.accountName || 'N/A'}`, 16, 58);
    doc.text(`Account No:       XXXX${accountDetails.mask || 'N/A'}`, 16, 68);

    // PART 2: Statement Information
    doc.setTextColor(41, 128, 185);
    doc.setFontSize(14);
    doc.rect(14, 80, doc.internal.pageSize.width - 28, 40);
    doc.setFillColor(235, 245, 251);
    doc.rect(14, 80, doc.internal.pageSize.width - 28, 10, 'F');
    doc.text('Statement Information', 16, 88);

    doc.setFontSize(11);
    doc.setTextColor(0, 0, 0);
    doc.text(
      `Statement Starting Date: ${fromDate !== '' ? moment.utc(fromDate).format('YYYY-MM-DD') : moment.utc(noDateTransactionDates?.fromDateNoData).format('YYYY-MM-DD') || 'N/A'}`,
      16,
      98
    );

    doc.text(
      `Statement Ending Date: ${toDate !== '' ? moment.utc(toDate).format('YYYY-MM-DD') : moment.utc(noDateTransactionDates?.toDateNoData).format('YYYY-MM-DD') || 'N/A'}`,
      16,
      108
    );

    // PART 3: Account Holder Information
    doc.setTextColor(41, 128, 185);
    doc.setFontSize(14);
    doc.rect(170, 80, doc.internal.pageSize.width - 184, 40);
    doc.setFillColor(235, 245, 251);
    doc.rect(170, 80, doc.internal.pageSize.width - 184, 10, 'F');
    doc.text('Account Holder Information', 172, 88);

    doc.setFontSize(11);
    doc.setTextColor(0, 0, 0);
    doc.text(`Account Holder Name:  ${accountDetails.user.name || 'N/A'}`, 172, 98);
    const maxWidth = 100;

    const accountHolderAddress = doc.splitTextToSize(`Account Holder Address: ${accountDetails.address || 'N/A'}`, maxWidth);
    doc.text(accountHolderAddress, 172, 108);

    // PART 4: Filter Transactions
    const pendingTransactions = transactions.filter(t => t.pending === true);
    const otherTransactions = transactions.filter(t => t.pending !== true);

    const groupedPendingTransactions = pendingTransactions.map(transaction => [
      moment.utc(transaction.date).format('YYYY-MM-DD'),
      transaction.name,
      `${transaction.amount < 0 ? '-' : ''} $${Math.abs(parseFloat(transaction.amount)).toFixed(2)}`
    ]);

    const sortedHistoryBalanceDates = historyBalanceDates.sort((a, b) => {
      return moment.utc(a.date).toDate() - moment.utc(b.date).toDate();
    });

    const groupTransactionsByDate = (transactions) => {
      return transactions.reduce((grouped, transaction) => {
        const date = moment.utc(transaction.date).format('YYYY-MM-DD');
        if (!grouped[date]) {
          grouped[date] = [];
        }
        grouped[date].push(transaction);
        return grouped;
      }, {});
    };

    // Function to get the corresponding balance for the last transaction of a day
    const getDailyBalance = (transactionDate, isLastTransaction) => {
      if (!isLastTransaction) {
        return '-'; // If it's not the last transaction of the day, return "-"
      }

      const matchingDate = sortedHistoryBalanceDates.find(balanceDate =>
        moment.utc(balanceDate.date).format('YYYY-MM-DD') === moment.utc(transactionDate).format('YYYY-MM-DD')
      );



      return matchingDate
        ? `${matchingDate.balance < 0 ? '-' : ''}$${Math.abs(parseFloat(matchingDate.balance)).toFixed(2)}`
        : 'N/A'; // Return balance for the last transaction of the day, else return "N/A"
    };

    const groupedOtherTransactions = groupTransactionsByDate(otherTransactions);

    // Separate and sort transactions based on type
    let creditTransactionRows = [];
    let debitTransactionRows = [];
    let totalCreditAmount = 0;
    let totalDebitAmount = 0;

    if (accountDetails.type === 'depository') {
      // Credit transactions
      creditTransactionRows = Object.keys(groupedOtherTransactions).flatMap(date => {
        const dateTransactions = groupedOtherTransactions[date].filter(transaction => transaction.amount < 0);
        return dateTransactions.map((transaction, index) => {
          const isLastTransaction = index === dateTransactions.length - 1;
          totalCreditAmount += transaction.amount;
          return {
            date: moment.utc(transaction.date).toDate(),
            rowData: [
              moment.utc(transaction.date).format('YYYY-MM-DD'),
              transaction.date
                ? moment.utc(transaction.date).subtract(1, 'day').format('YYYY-MM-DD')
                : "N/A",
              transaction.name,
              `${transaction.amount < 0 ? '-' : ''} $${Math.abs(parseFloat(transaction.amount)).toFixed(2)}`,
              getDailyBalance(moment.utc(transaction.date).toDate(), isLastTransaction)
            ]
          };
        });
      }).sort((a, b) => a.date - b.date);

      // Debit transactions
      debitTransactionRows = Object.keys(groupedOtherTransactions).flatMap(date => {
        const dateTransactions = groupedOtherTransactions[date].filter(transaction => transaction.amount > 0);
        return dateTransactions.map((transaction, index) => {
          const isLastTransaction = index === dateTransactions.length - 1;
          totalDebitAmount += transaction.amount;
          return {
            date: moment.utc(transaction.date).toDate(),
            rowData: [
              moment.utc(transaction.date).format('YYYY-MM-DD'),
              transaction.date
                ? moment.utc(transaction.date).subtract(1, 'day').format('YYYY-MM-DD')
                : "N/A",
              transaction.name,
              `${transaction.amount < 0 ? '-' : ''} $${Math.abs(parseFloat(transaction.amount)).toFixed(2)}`,
              getDailyBalance(moment.utc(transaction.date).toDate(), isLastTransaction)
            ]
          };
        });
      }).sort((a, b) => a.date - b.date);
    } else if (accountDetails.type === 'credit') {
      creditTransactionRows = Object.keys(groupedOtherTransactions).flatMap(date => {
        const dateTransactions = groupedOtherTransactions[date].filter(transaction => transaction.amount < 0);
        return dateTransactions.map((transaction, index) => {
          const isLastTransaction = index === dateTransactions.length - 1;
          totalCreditAmount += transaction.amount;
          return {
            date: moment.utc(transaction.date).toDate(),
            rowData: [
              moment.utc(transaction.date).format('YYYY-MM-DD'),
              transaction.date
                ? moment.utc(transaction.date).format('YYYY-MM-DD')
                : "N/A",
              transaction.name,
              `${transaction.amount < 0 ? '-' : ''} $${Math.abs(parseFloat(transaction.amount)).toFixed(2)}`,
              getDailyBalance(moment.utc(transaction.date).toDate(), isLastTransaction)
            ]
          };
        });
      }).sort((a, b) => a.date - b.date);

      // Debit transactions
      debitTransactionRows = Object.keys(groupedOtherTransactions).flatMap(date => {
        const dateTransactions = groupedOtherTransactions[date].filter(transaction => transaction.amount > 0);
        return dateTransactions.map((transaction, index) => {
          const isLastTransaction = index === dateTransactions.length - 1;
          totalDebitAmount += transaction.amount;
          return {
            date: moment.utc(transaction.date).toDate(),
            rowData: [
              moment.utc(transaction.date).format('YYYY-MM-DD'),
              transaction.date
                ?  moment.utc(transaction.date).format('YYYY-MM-DD')
                : "N/A",
              transaction.name,
              `${transaction.amount < 0 ? '-' : ''} $${Math.abs(parseFloat(transaction.amount)).toFixed(2)}`,
              getDailyBalance(moment.utc(transaction.date).toDate(), isLastTransaction)
            ]
          };
        });
      }).sort((a, b) => a.date - b.date);
    }

    // PART 4: Account Summary with styled box
    doc.setTextColor(41, 128, 185);
    doc.setFontSize(14);
    doc.rect(14, 130, doc.internal.pageSize.width - 28, 40); // Border for Account Summary
    doc.setFillColor(235, 245, 251);
    doc.rect(14, 130, doc.internal.pageSize.width - 28, 10, 'F');
    doc.text('Account Summary', 16, 138);

    doc.setFontSize(11);
    doc.setTextColor(0, 0, 0);


    const openingBalance = openCloseDetails?.Open?.balance ?? accountDetails.openingBalance ?? 0;
    const openingBalanceSign = openingBalance < 0 ? '-' : '';
    // const openingBalanceDate = openCloseDetails?.Open?.date || accountDetails.openingBalanceDate || '';
    const openingBalanceDate = openCloseDetails?.Open?.date ? new Date(new Date(openCloseDetails?.Open?.date).setDate(new Date(openCloseDetails?.Open?.date).getDate() + 1)).toISOString().split('T')[0] : new Date(new Date(accountDetails?.openingBalanceDate).setDate(new Date(accountDetails?.openingBalanceDate).getDate() + 1)).toISOString().split('T')[0] || '';


    doc.text(
      `Opening Balance: ${openingBalanceSign} $${Math.abs(openingBalance).toFixed(2)} ${openingBalanceDate ? `(${openingBalanceDate})` : ''}`,
      16,
      148
    );

    doc.text(`Total Credited Amount: ${totalCreditAmount < 0 ? "-" : ''} $${Math.abs(totalCreditAmount || 0).toFixed(2)} `, 16, 158);
    doc.text(`Total Debited Amount:  ${totalDebitAmount < 0 ? "-" : ''} $${Math.abs(totalDebitAmount || 0).toFixed(2)} `, 16, 168);

    const closingBalance = openCloseDetails?.close?.balance ?? accountDetails.closingBalance ?? 0;
    const closingBalanceSign = closingBalance < 0 ? '-' : '';
    const closingBalanceDate = openCloseDetails?.close?.date || accountDetails.closingBalanceDate || '';

    doc.text(
      `Closing Balance: ${closingBalanceSign} $${Math.abs(closingBalance).toFixed(2)} ${closingBalanceDate ? `(${closingBalanceDate})` : ''}`,
      172,
      148

    );
    doc.text(`Account Type:         ${accountDetails.type || 'N/A'}`, 172, 158);
    doc.text(`Account Sub Type:     ${accountDetails.subtype || 'N/A'}`, 172, 168);


    // PART 5: Display Pending Transactions
    doc.addPage();
    let currentYPosition = 20;
    doc.setTextColor(41, 128, 185);
    doc.setFontSize(14);
    doc.text('Account History', 14, currentYPosition);


    doc.setFontSize(11);
    doc.setTextColor(0, 0, 0);
    doc.text('Pending Transactions:', 14, currentYPosition + 10);

    doc.autoTable({
      head: [['Date', 'Description', 'Amount']],
      body: groupedPendingTransactions.length > 0 ? groupedPendingTransactions : [['N/A', 'N/A', 'N/A']],
      startY: currentYPosition + 20,
      theme: 'grid',
      styles: {
        fontSize: 10,
        cellPadding: 2,
        fillColor: [245, 245, 245],
      },
      headStyles: {
        fillColor: [41, 128, 185],
        textColor: [255, 255, 255],
      },
      alternateRowStyles: {
        fillColor: [245, 245, 245],
      },
    });

    // PART 6: Display Credit and Debit Transactions
    doc.text('Credit Transactions:', 14, doc.autoTable.previous.finalY + 20);

    const sortedCreditTransactionRows = creditTransactionRows.map(t => t.rowData);
    doc.autoTable({
      head: [['Post Date', 'Suggest Date', 'Description', 'Amount', 'Ending Daily Balance']],
      body: sortedCreditTransactionRows.length > 0 ? sortedCreditTransactionRows : [['N/A', 'N/A', 'N/A', 'N/A', 'N/A']],
      startY: doc.autoTable.previous.finalY + 30,
      theme: 'grid',
      styles: {
        fontSize: 10,
        cellPadding: 2,
        fillColor: [245, 245, 245],
      },
      headStyles: {
        fillColor: [41, 128, 185],
        textColor: [255, 255, 255],
      },
      alternateRowStyles: {
        fillColor: [245, 245, 245],
      },
    });

    const sortedDebitTransactionRows = debitTransactionRows.map(t => t.rowData);
    doc.text('Debit Transactions:', 14, doc.autoTable.previous.finalY + 20);
    doc.autoTable({
      head: [['Post Date', 'Suggest Date', 'Description', 'Amount', 'Ending Daily Balance']],
      body: sortedDebitTransactionRows.length > 0 ? sortedDebitTransactionRows : [['N/A', 'N/A', 'N/A', 'N/A', 'N/A']],
      startY: doc.autoTable.previous.finalY + 30,
      theme: 'grid',
      styles: {
        fontSize: 10,
        cellPadding: 2,
        fillColor: [245, 245, 245],
      },
      headStyles: {
        fillColor: [41, 128, 185],
        textColor: [255, 255, 255],
      },
      alternateRowStyles: {
        fillColor: [245, 245, 245],
      },
    });

    // Page numbering
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      const text = `Page ${i} of ${pageCount}`;
      const textWidth = doc.getTextWidth(text);
      const x = (doc.internal.pageSize.width - textWidth) / 2;
      const y = doc.internal.pageSize.height - 10;
      doc.setFontSize(10);
      doc.text(text, x, y);
    }

    // Save the PDF
    doc.save('transactions.pdf');
  };



  return (
    <div className='w-[80vw] p-5 pb-0 max-h-[100vh] h-screen text-gray-900 overflow-y-auto'>
      <ToastContainer />
      <div className="ml-auto w-1/4 flex justify-end mb-3 mt-2">
        <button onClick={() => {
          Cookies.remove('k9_jwt_token');
          localStorage.removeItem('k9role')
          navigate('/');

        }} className="bg-[#000AFF] font-medium text-white px-4 text-sm py-2 rounded-md">Logout</button>

      </div>

      <div className="text-left text-sm w-full">
        {accountLoader ?
          <div className="flex justify-between items-center w-full h-[90px]">
            <div className="flex justify-center items-center w-3/4">
              <TailSpin
                visible={true}
                height="50"
                width="50"
                color="#000AFF"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass="" />
            </div>
          </div> :
          <div className="mb-5 flex justify-between items-center w-full">
            <div className="flex justify-between items-center w-3/4 mt-2">
              <div className="">
                <p className="flex text-sm"><span className="text-gray-800 font-semibold mr-1">Account Name:</span> {accountDetails.accountName !== null ? accountDetails.accountName : "N/A"}</p>
                <p className="flex text-sm"><span className="text-gray-800 font-semibold mr-1">Official Name:</span> {accountDetails.officialName !== null ? accountDetails.officialName : "N/A"}</p>
                <p className="flex text-sm"><span className="text-gray-800 font-semibold mr-1">Account No:</span> {accountDetails.mask !== null ? "XXXX " + accountDetails.mask : "N/A"}</p>
                <p className="flex text-sm"><span className="text-gray-800 font-semibold mr-1">Account Type:</span> {accountDetails.type !== null ? accountDetails.type : "N/A"}</p>
              </div>
              <div className="mr-5 flex flex-col justify-between">
                <p className="flex"><span className="text-gray-800 font-semibold mr-1 ml-1">Account Sub Type:</span> {accountDetails.subtype !== null ? accountDetails.subtype : "N/A"}</p>
                <div>

                  {openCloseDetails.Open !== undefined ?
                    <p className="flex">
                      <span className="text-gray-800 font-semibold ml-1">Opening Balance:</span>
                      {openCloseDetails?.Open?.balance !== null && openCloseDetails?.Open?.balance !== undefined
                        ? `${openCloseDetails?.Open?.balance < 0 ? '-' : ''} $${Math.abs(openCloseDetails?.Open?.balance).toFixed(2)}`
                        : 'N/A'}
                      {openCloseDetails?.Open?.date && (
                        // <span className="ml-3 italic">({openCloseDetails?.Open?.date})</span>
                        <span className="ml-3 italic">({new Date(new Date(openCloseDetails?.Open?.date).setDate(new Date(openCloseDetails?.Open?.date).getDate() + 1)).toISOString().split('T')[0]})</span>
                      )}
                    </p>
                    :
                    <p className="flex">
                      <span className="text-gray-800 font-semibold ml-1">Opening Balance:</span>
                      {accountDetails.openingBalance !== null && accountDetails.openingBalance !== undefined
                        ? `${accountDetails.openingBalance < 0 ? '-' : ''} $${Math.abs(accountDetails.openingBalance).toFixed(2)}`
                        : 'N/A'}
                      {accountDetails.openingBalanceDate && (
                        <span className="ml-3 italic">({new Date(new Date(accountDetails.openingBalanceDate).setDate(new Date(accountDetails.openingBalanceDate).getDate() + 1)).toISOString().split('T')[0]})</span>
                      )}
                    </p>}
                  {openCloseDetails.close !== undefined ?
                    <p className="flex">
                      <span className="text-gray-800 font-semibold ml-1">Closing Balance:</span>
                      {openCloseDetails?.close?.balance !== null && openCloseDetails?.close?.balance !== undefined
                        ? `${openCloseDetails?.close?.balance < 0 ? '-' : ''} $${Math.abs(openCloseDetails?.close?.balance).toFixed(2)}`
                        : 'N/A'}
                      {openCloseDetails?.close?.date && (
                        <span className="ml-3 italic">({openCloseDetails?.close?.date})</span>
                      )}
                    </p>
                    :
                    <p className="flex">
                      <span className="text-gray-800 font-semibold ml-1">Closing Balance:</span>
                      {accountDetails.closingBalance !== null && accountDetails.closingBalance !== undefined
                        ? `${accountDetails.closingBalance < 0 ? '-' : ''} $${Math.abs(accountDetails.closingBalance).toFixed(2)}`
                        : 'N/A'}
                      {accountDetails.closingBalanceDate && (
                        <span className="ml-3 italic">({accountDetails.closingBalanceDate})</span>
                      )}
                    </p>
                  }

                </div>

              </div>
            </div>

            <div className=' border flex justify-center items-center p-2 rounded-lg shadow-xl'>
              <div className="flex justify-center items-center mx-2 relative w-16 h-16">
                <div className="flex justify-center items-center absolute top-0 left-0 w-full h-full rounded-full border-4 border-[#000AFF]"></div>
                <div className="flex justify-center items-center">
                  <div
                    className="absolute top-0 left-0 w-full h-full rounded-full border-4 border-transparent border-t-white" style={{ transform: "rotate(-20deg)" }}>
                  </div>
                  <p className={`${transactions.length.toString().length > 6 && "text-[12px]"}`}>{transactions.length}</p>
                </div>
              </div>
              <h1 className=' font-semibold text-gray-500'>Transactions Count</h1>
            </div>
          </div>
        }
      </div>
      <div className="flex justify-around space-x-2 items-center mt-4">
        <div className="">
          <label htmlFor="number-select" className="text-sm text-gray-600 font-semibold mr-2">Entries per page</label>
          <select
            id="number-select"
            value={entries}
            onChange={handleChangeEntries}
            className="border-2 rounded-lg text-sm text-gray-700 border-gray-200 focus:border-gray-700 focus:outline-none h-8 "
          >
            <option value={15}>15</option>
            <option value={30}>30</option>
            <option value={50}>50</option>
          </select>
        </div>
        <div className="flex  justify-between items-center">
          <div className="flex items-center space-x-2">
            <div className="flex items-center space-x-1 bg-white p-1 rounded-lg">
              <div className="flex">
                <label htmlFor="from-date" className="text-md text-[#000AFF] font-semibold m-2">From</label>
                <input
                  type="date"
                  id="from-date"
                  value={fromDate}
                  onChange={(e) => {
                    setFromDate(e.target.value)
                  }}
                  className="border-2 rounded-lg text-gray-700 text-sm pl-2 border-gray-200 focus:border-gray-700 focus:outline-none h-8 mt-1"
                />
              </div>
              <div className="flex">
                <label htmlFor="to-date" className="text-md text-[#000AFF] font-semibold m-2">To</label>
                <input
                  type="date"
                  id="to-date"
                  value={toDate}
                  onChange={(e) => {
                    setToDate(e.target.value)
                  }}
                  className="border-2 rounded-lg text-gray-700 text-sm pl-2 border-gray-200 mt-1 focus:border-gray-700 focus:outline-none h-8"
                />
              </div>
              <div>

              </div>
              <div>
                {/* Clear button */}
                <button
                  onClick={() => {
                    handleClear()
                  }}
                  // disabled={fromDate === '' || toDate === ''}
                  className="border border-gray-300 px-2 py-2 rounded-lg shadow-md hover:bg-gray-200"
                >
                  <IoClose size={15} />
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="">
          <button
            onClick={openModal}
            className="bg-[#000AFF] font-medium text-white px-4 text-sm py-2 rounded-md">Report
          </button>

          <ReportPopup isOpen={isModalOpen} onClose={closeModal} fromDate={fromDate} toDate={toDate} accountId={accountId} />
        </div> */}

        <div className="relative inline-block text-left">
          <button
            onClick={toggleDropdown}
            className={`inline-flex justify-between items-center w-32 rounded-md px-4 py-2 bg-[#000AFF] text-white text-sm font-medium focus:outline-none ${isOpen && "rounded-b-none"}`}
          >
            Download <RiDownloadLine className="ml-2" />
          </button>


          {isOpen && (
            <div ref={dropdownRef} id="dropdown-menu" className="origin-top-right text-sm font-medium absolute right-0 w-32 rounded-t-none rounded-b-lg shadow-lg bg-[#000AFF] ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <button
                  onClick={downloadCSV}
                  className="text-white border-t flex justify-between items-center block w-full text-left px-4 py-2 text-sm bg-[#000AFF]"
                  role="menuitem"
                >
                  CSV <RiDownloadLine className="ml-2" />
                </button>
                <button
                  onClick={downloadExcel}
                  className="text-white border-t flex justify-between items-center block w-full text-left px-4 py-2 text-sm"
                  role="menuitem"
                >
                  Excel <RiDownloadLine className="ml-2" />
                </button>
                <button
                  onClick={downloadPDF}
                  className="text-white border-t flex justify-between items-center block w-full text-left px-4 py-2 text-sm"
                  role="menuitem"
                >
                  PDF <RiDownloadLine className="ml-2" />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className=" mb-1 bg-[#ffffff] h-[65vh] rounded-lg overflow-auto scrollbar-thin scrollbar-thumb-gray-800 scrollbar-track-gray-300">
        {userLoading ? <div className="flex flex-col justify-center items-center h-full">
          <TailSpin
            visible={true}
            height="80"
            width="80"
            color="#000AFF"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass="" /></div> : <>{
              transactions.length === 0 ?
                <div className="flex flex-col h-full justify-center items-center">
                  <img src={Nodata} alt="no-data" className="h-[300px]" />
                  <p className="text-[2px]"><a href="https://www.freepik.com/free-vector/hand-drawn-no-data-concept_55024593.htm#query=no%20data&position=1&from_view=keyword&track=ais_hybrid&uuid=9eeddd48-f712-47e5-8d8f-d0cf9a8ac1fe">Image by pikisuperstar</a> on Freepik</p>
                  <h1 className="text-2xl text-gray-800">No Transactions data found</h1>
                </div> : <>
                  <div className="w-full  overflow-x p-2">
                    <table className="w-full text-sm bg-white mt-2 rounded-lg">
                      <thead className="text-gray-800 border">
                        <tr className="">
                          <th className=" py-2 px-2">SR No</th>
                          <th className=" py-2 px-2">Plaid Transaction Id</th>
                          <th className=" py-2 px-2">Description</th>
                          <th className=" py-2 px-2">Date</th>
                          {/* <th className=" py-2 px-2">Category</th> */}
                          <th className=" py-2 px-2">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems.map((transaction, index) => (
                          <tr key={transaction.id} className=" text-gray-600 border">
                            <td className="py-2 px-2">{(index + 1) + ((currentPage - 1) * entries)}</td>
                            <td className=" py-2 px-2">{transaction.plaidTransactionId}</td>
                            <td className=" py-2 px-2">{transaction.name}</td>
                            <td className=" py-2 px-2">{moment.utc(transaction.date).format('YYYY-MM-DD')}</td>
                            <td className={`py-2 px-2 `}>{transaction.amount < 0 ? '-' : ''} ${Math.abs(transaction.amount)}</td>

                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
            }</>
        }
      </div>
      {!userLoading && transactions.length !== 0 &&
        <div className="flex justify-between items-center pl-2 pr-2">
          <button
            className={`rounded-lg bg-[#ffffff] border border-gray-400 text-gray-800 m-1 p-1 ${currentPage === 1 ? 'cursor-not-allowed' : ' focus:outline-none focus:shadow-outline'}`}
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span className='text-gray-800'>Page {currentPage} of {totalPages}</span>
          <button
            className={`rounded-lg bg-[#ffffff] border border-gray-400 text-gray-800 m-1 p-1 ${currentPage === totalPages ? 'cursor-not-allowed' : 'focus:outline-none focus:shadow-outline'}`}
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      }
    </div>
  )
}
export default TransactionsContentArea