import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { usePlaidLink } from "react-plaid-link";
import k9logo2 from './../../assets/K9logo2 .png'

const UserLink = () => {
  const { userId } = useParams();
  const [linkToken, setLinkToken] = useState(null);
  const [publicToken, setPublicToken] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState(false)
  const createLinkToken = async (userId) => {
    try {
      setLoader(true)
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/create-link-token`,
        { userId },
      );
      if (response.data.success) {
        const data = response.data;
        setLinkToken(data.link_token);
      }
    } catch (error) {
      console.error("Error creating Link token:", error);
      toast.error("Error creating Link token");
      setLoader(false);
    }
  };


  const { open, ready } = usePlaidLink({
    token: linkToken,
    onSuccess: async (public_token, metadata) => {
      setPublicToken(public_token);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/get-access-token`,
          { public_token, userId },
        );
        if (response.data.success) {
          const data = response.data;
          setAccessToken(data.access_token);
          setShowSuccess(true);
        }
      } catch (error) {
        console.error("Error exchanging public token:", error);
        toast.error(error.response.data.error.message)
        setLoader(false)
      }
    },
    onExit: (error, metadata) => {
      if (error) {
        toast.error("Error exiting plaid")
      }
      setLoader(false);
    },
  });

  useEffect(() => {
    if (linkToken && ready) {
      open();
    }
  }, [linkToken, ready, open]);

  return (
    <div className="h-[100vh] overflow-y-auto">
      <div className='h-[10vh] p-5 font-semibold text-lg bg-gray-900 flex justify-left items-center'>
        <img src={k9logo2} alt='logo' className='w-20' />
      </div>
      <ToastContainer />
      <div className='h-[90vh] flex flex-col items-center text-gray-900'>
        {success ?
          <div className='mt-10'>
            <h1 className='mb-10 text-xl font-normal '>Accounts linked successfully!</h1>
            <button onClick={() => { createLinkToken(userId) }} className='bg-blue-600 text-white p-2 rounded-lg w-min-20'>
              {loader ? <div className="flex justify-center items-center">
                <div className="spinner" style={{
                  border: "2px solid rgba(255, 255, 255, 0.3)",
                  borderRadius: "50%",
                  borderTop: "2px solid #fff",
                  width: "20px",
                  height: "20px",
                  animation: "spin 1s linear infinite",
                }} />

              </div> : "Link additional accounts"}</button>
            <p className="mt-5">If you’ve linked all your accounts, Please close this page.</p>
            <p className="mt-5 text-gray-500 text-sm">Please reach out to us at (929) 416-4116 if you have any questions</p>
          </div> :
          <div className='mt-10'>
            <h1 className='mb-10 text-xl font-normal '>Securely link your bank account by clicking the button below to get started with Plaid.</h1>
            <button onClick={() => { createLinkToken(userId) }} className='bg-blue-600 text-white p-2 rounded-lg w-20'>
              {loader ? <div className="flex justify-center items-center">
                <div className="spinner" style={{
                  border: "2px solid rgba(255, 255, 255, 0.3)",
                  borderRadius: "50%",
                  borderTop: "2px solid #fff",
                  width: "20px",
                  height: "20px",
                  animation: "spin 1s linear infinite",
                  marginRight: "10px"
                }} />

              </div> : "Link"}</button>
          </div>}

        {showSuccess && (
          <div style={{
            position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)", backgroundColor: "#fff", color: "#000", padding: "20px", borderRadius: "5px", boxShadow: "0 0 10px rgba(0,0,0,0.25)"
          }}>
            <h2 className="text-[#041340] text-xl mb-4 font-bold">Success!</h2>
            <p>Your bank account has been successfully linked.</p>
            <button
              className="mt-5 p-2.5 bg-[#041340] text-[#fff] rounded-md"
              onClick={() => {
                setShowSuccess(false)
                setLoader(false)
                setSuccess(true)
              }}>
              Close
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
export default UserLink;