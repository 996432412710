import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import Cookies from 'js-cookie';



const EditEmailContent = () => {
    const [htmlContent, setHtmlContent] = useState('');
    const token = Cookies.get('k9_jwt_token')

    useEffect(()=>{
        getEmailTemplate()
    },[])

    const getEmailTemplate=async()=>{
        try {
            const response = await axios.get(
              `${process.env.REACT_APP_API_URL}/api/get-email-template`,
              {
                headers: {
                  Authorization: `Bearer ${token}`
                }
              },
            );
            if (response.data.success) {
            //   toast.success(response.data.message)
              setHtmlContent(response.data.data.html)
            }
          } catch (error) {
            console.error("Error fetching email template:", error);
            toast.error(error.response.data.message)
           }
    }

    const onSave = async(htmlContent)=>{
        try {
            const response = await axios.post(
              `${process.env.REACT_APP_API_URL}/api/email-template`,
              {htmlContent},
              {
                headers: {
                  Authorization: `Bearer ${token}`
                }
              },
            );
            if (response.data.success) {
              toast.success(response.data.message)
            }
          } catch (error) {
            console.error("Error saving email template:", error);
            toast.error(error.response.data.message)
           }
    }

    const handleSave = () => {
        onSave(htmlContent)
    };

    return (
        <div className='w-[80vw] p-20 h-[100vh] overflow-y-auto'>
            <ToastContainer />
            <h2 className='font-semibold mb-5'>Email Template Editor</h2>
            <div className="w-full">
                <ReactQuill
                    theme="snow"
                    value={htmlContent}
                    onChange={setHtmlContent}
                    modules={{
                        toolbar: [
                            [{ header: '1' }, { header: '2' }, { font: [] }],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            ['bold', 'italic', 'underline'],
                            [{ align: [] }],
                            ['link', 'image'],
                            ['clean'],
                        ],
                    }}
                />
                <button onClick={handleSave} className="bg-[#000AFF] text-white p-2 mt-4 rounded-md">
                    Save Template
                </button>
            </div>
        </div>
    )
}

export default EditEmailContent;

