import { useEffect, useState } from "react";
import { TailSpin } from 'react-loader-spinner'
import { IoSyncSharp } from "react-icons/io5";
import { useParams } from 'react-router-dom';
import axios from "axios";
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { SiAuthy } from "react-icons/si";
import { IoClose } from "react-icons/io5";

const BanksAndAccountsContentArea = () => {
  const [loading, setLoading] = useState(true)
  const [spinning, setSpinning] = useState('')
  const [blinking, setBlinking] = useState('')

  const [institutionLoading, setInstitutionLoading] = useState(false)
  const [accountsLoader, setAccountsLoader] = useState(false)
  const { userId } = useParams();
  const [userDetails, setUserDetails] = useState({})
  const [institutions, setInstitutions] = useState([])
  const [selectInsId, setSelectInsId] = useState('')
  const [selectInsName, setSelectInsName] = useState('')
  const [accessToken, setAccessToken] = useState('')
  const navigate = useNavigate();
  const [userAccountsData, setUserAccountsData] = useState([])
  const [transactionLoading, setTransactionLoading] = useState(false)
  const [allTransactions, setAllTransactions] = useState([])


  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items per page

  // Calculate total pages
  const totalPages = Math.ceil(userAccountsData.length / itemsPerPage);

  // Get current page data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = userAccountsData.slice(indexOfFirstItem, indexOfLastItem);


  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const moment = require('moment');

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };


  const token = Cookies.get('k9_jwt_token')


  const getUser = async () => {
    try {
      setLoading(true)
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/users/get-user-details`, { userId },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        },
      );
      if (response.data.success) {
        setUserDetails(response.data.data)
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
      toast.error("Error fetching user account")
    } finally {
      setLoading(false);
    }
  }

  const getInstitutions = async () => {
    try {
      setInstitutionLoading(true)
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/all-instiutions/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        },
      );
      setInstitutions(response.data.data)
      if (response.data.data.length !== 0) {
        setSelectInsId(response.data.data[0].insId)
        setSelectInsName(response.data.data[0].insName)
      }
    } catch (error) {
      console.error("Error fetching institutions list", error);
      toast.error("Error fetching institutions")
    } finally {
      setInstitutionLoading(false)
    }
  }

  const loadAccounts = async (selectInsId) => {
    try {
      setAccountsLoader(true)
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/all-accounts/${selectInsId}?userId=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        },);
      if (response.data.success) {
        setUserAccountsData(response.data.data)
      }
    } catch (error) {
      console.error("Error fetching user accounts:", error);
      toast.error("Error fetching accounts")
    } finally {
      setAccountsLoader(false)
    }
  }

  useEffect(() => {
    getInstitutions()
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getUser()
    getTransactions(userId)
    // eslint-disable-next-line
  }, [userId])

  useEffect(() => {
    if (selectInsId !== '') {
      loadAccounts(selectInsId)
    }
    // eslint-disable-next-line
  }, [selectInsId])


  const syncTransactions = async (accountId, accessToken, institutionId) => {
    try {
      const data = {
        user_id: userId,
        access_token: accessToken,
        accountId: accountId,
        institutionId: institutionId,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/sync-transactions`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.success) {
        toast.success(response.data.message);
        loadAccounts(selectInsId);
      }
    } catch (error) {
      console.error("Error syncing details:", error);
      toast.error(error.response.data.message);
    } finally {
      setSpinning('');
    }
  }

  const reAuthAccounts = async (accountId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/send-re-auth`,
        { accountId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.success) {
        toast.success(response.data.message);
      }
    } catch (error) {
      console.error("Error Re-auth details:", error);
      toast.error(error.response.data.message);
    } finally {
      setBlinking('');
    }
  }

  const handleClear = () => {
    setFromDate('');
    setToDate('');; // Fetch without filters
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const ReportPopup = ({ isOpen, onClose, fromDate, toDate, userId }) => {
    const [reportLoader,setReportLoader] = useState(false)
    const [reportData, setReportData] = useState([]);
    const [selectedYearData, setSelectedYearData] = useState(null);
    const [activeYear, setActiveYear] = useState(null);
    // Fetch report data from the backend when the component is opened
    useEffect(() => {
      if (isOpen && fromDate && toDate && userId) {
        fetchReportData(fromDate, toDate, userId);
      }
    }, [isOpen, fromDate, toDate, userId]);

    const fetchReportData = async (fromDate, toDate, userId) => {
      try {
        setReportLoader(true)
        const formattedFromDate = moment(fromDate).format("MM/DD/YYYY");
        const formattedToDate = moment(toDate).format("MM/DD/YYYY");

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/users/report-all-accounts`,
          {
            headers: {
              Authorization: `Bearer ${token}`,  // Include the Authorization header
            },
            params: {
              fromDate: formattedFromDate,
              toDate: formattedToDate,
              userId
            },
          }
        );
        console.log(response.data)
        const data = response.data;

        if (data.success) {
          setReportData(data.data);

          // Automatically select the first year by default
          if (data.data.length > 0) {
            const firstYearData = data.data[0];
            setActiveYear(firstYearData.year);
            setSelectedYearData(firstYearData);
          }
        } else {
          setReportData([])
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error fetching the report data:", error);
        
      }finally{
        setReportLoader(false)
      }
    };

    const handleYearClick = (year) => {
      const yearData = reportData.find((report) => report.year === year);
      setSelectedYearData(yearData);
      setActiveYear(year);
    };

    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
        <div className="bg-white p-8 rounded-lg shadow-lg w-3/4 max-w-2xl">
          <h2 className="text-left text-lg font-bold">Transaction Report</h2>
          <hr className="my-4" />
          {reportLoader?
          <div className="flex justify-center items-center">
            <TailSpin
            visible={true}
            height="80"
            width="80"
            color="#000AFF"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
          />
          </div>: 
          <>{reportData.length===0? <p>No transactions found</p>:
            <div>
            <div className="flex mb-4">
              {/* Year List */}
              {reportData.map((report) => (
                <button
                  key={report.year}
                  className={`px-4 py-2 rounded-lg border-2 text-black hover:bg-blue-600 hover:text-white
                    ${activeYear === report.year ? 'bg-blue-600  text-white' : 'border-2 text-black border-gray-200 rounded-lg'}`}
                  onClick={() => handleYearClick(report.year)}
                >
                  {report.year}
                </button>
              ))}
            </div>
            {/* Table with transactions */}
            {selectedYearData && (
              <>
                <table className="w-full mt-4 border-collapse border">
                  <thead>
                    <tr className="text-left text-sm">
                      <th className="border px-4 py-2">S.No</th>
                      <th className="border px-4 py-2">Month</th>
                      <th className="border px-4 py-2">No. of Transactions</th>
                      <th className="border px-4 py-2">Pricing Plan ($)</th> {/* New column for Amount */}
                    </tr>
                  </thead>
                  <tbody>
                    {selectedYearData.months.map((monthData, index) => (
                      <tr key={monthData.month} className="text-left text-sm">
                        <td className="border px-4 py-2">{index + 1}</td>
                        <td className="border px-4 py-2">{monthData.month}</td>
                        <td className="border px-4 py-2">{monthData.count}</td>
                        <td className="border px-4 py-2">${monthData.amount.toFixed(2)}</td> {/* Display amount */}
                      </tr>
                    ))}
                    {/* Total Count and Amount Row */}
                    <tr className="text-left text-sm">
                      <td className="border px-4 py-2 font-bold" colSpan="2">Total</td>
                      <td className="border px-4 py-2 font-bold">{selectedYearData.totalCount}</td>
                      <td className="border px-4 py-2 font-bold">${selectedYearData.totalAmount.toFixed(2)}</td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}
           
            </div>
          }</>
           }
            {/* Close button */}
            <div className="flex justify-center mt-6">
              <button
                className="bg-[#000AFF] font-medium text-white px-4 text-sm py-2 rounded-md"
                onClick={onClose}
              >
                Close
              </button>
            </div>
        </div>
      </div>
    );
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    getTransactions(userId)
  }, [fromDate, toDate]);

  const getTransactions = async (userId) => {
    try {
      setTransactionLoading(true);

      // Define params object for filtering transactions
      const params = {
        // categorySearch: categorySearch, // Apply search only if it's set
      };
      if (fromDate) params.fromDate = moment.utc(fromDate, 'YYYY-MM-DD').startOf('day').format('YYYY-MM-DD HH:mm:ss');
      if (toDate) params.toDate = moment.utc(toDate, 'YYYY-MM-DD').endOf('day').format('YYYY-MM-DD HH:mm:ss');
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/all-transactions-user/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params,
        }
      );

      if (response.data.success) {
        setAllTransactions(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching Transactions", error);
      toast.error("Error fetching Transactions");
    } finally {
      setTransactionLoading(false);
    }
  };


  return (
    <div className="w-[80vw] p-10 pt-5 pb-2 text-gray-900 h-[100vh] overflow-y-auto ">
      <ToastContainer />
      <div className="ml-auto w-1/4 flex justify-end mb-3 mt-2">
        <button onClick={() => {
          Cookies.remove('k9_jwt_token');
          localStorage.removeItem('k9role')
          navigate('/');

        }} className="bg-[#000AFF] font-medium text-white px-4 text-sm py-2 rounded-md">Logout</button>

      </div>
      {loading || (institutionLoading || accountsLoader) ?
        <div className='h-[500px] flex justify-center items-center'>
          <TailSpin
            visible={true}
            height="80"
            width="80"
            color="#000AFF"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div> :
        <div>
          <div className="mb-2 flex justify-between">
            <div>
              <p className="flex text-sm"><span className="text-gray-800 font-semibold mr-3">Customer:</span> {userDetails.name}</p>
              <p className="flex text-sm"><span className="text-gray-800 font-semibold mr-3">Email:</span> {userDetails.email}</p>
              <p className="flex text-sm"><span className="text-gray-800 font-semibold mr-3 ">Phone No:</span> {userDetails.phoneNumber}</p>
            </div>
            <div>
              <div className="flex  justify-between items-center">
                <div className="flex items-center space-x-2">
                  <div className="flex items-center space-x-1 bg-white p-1 rounded-lg">
                    <div className="mr-4">
                      <div className="space-x-2 flex justify-center items-center">
                        {/* from */}
                        <div className="flex">
                          <label htmlFor="from-date" className="text-md text-[#000AFF] font-semibold m-2">From</label>
                          <input
                            type="date"
                            id="from-date"
                            value={fromDate}
                            onChange={(e) => {
                              setFromDate(e.target.value)
                            }}
                            className="border-2 rounded-lg text-gray-700 text-sm pl-2 border-gray-200 focus:border-gray-700 focus:outline-none h-8 mt-1"
                          />
                        </div>
                        {/* to */}
                        <div className="flex">
                          <label htmlFor="to-date" className="text-md text-[#000AFF] font-semibold m-2">To</label>
                          <input
                            type="date"
                            id="to-date"
                            value={toDate}
                            onChange={(e) => {
                              setToDate(e.target.value)
                            }}
                            className="border-2 rounded-lg text-gray-700 text-sm pl-2 border-gray-200 mt-1 focus:border-gray-700 focus:outline-none h-8"
                          />
                        </div>
                        {/* Clear button */}
                        <div>
                          <button
                            onClick={() => {
                              handleClear()
                            }}
                            // disabled={fromDate === '' || toDate === ''}
                            className="border border-gray-300 px-2 py-2 rounded-lg shadow-md hover:bg-gray-200"
                          >
                            <IoClose size={15} />
                          </button>
                        </div>
                      </div>
                      {/* button report*/}
                      <div className="mt-4">
                        <button
                          disabled={fromDate === '' || toDate === ''}
                          onClick={openModal}
                          className={`bg-[#000AFF] font-medium text-white px-4 text-sm py-2 rounded-md ${(fromDate === '' || toDate === '') && 'bg-[#535afc] cursor-not-allowed'}`}>Report
                        </button>
                      </div>
                    </div>

                    <div className=' border flex justify-center items-center p-2 rounded-lg shadow-xl'>
                      <div className="flex justify-center items-center mx-2 relative w-16 h-16">
                        <div className="flex justify-center items-center absolute top-0 left-0 w-full h-full rounded-full border-4 border-[#000AFF]"></div>
                        <div className="flex justify-center items-center">
                          <div
                            className="absolute top-0 left-0 w-full h-full rounded-full border-4 border-transparent border-t-white" style={{ transform: "rotate(-20deg)" }}>
                          </div>
                          {transactionLoading ? <TailSpin
                            visible={true}
                            height="30"
                            width="30"
                            color="#000AFF"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                          /> : <p className={`${allTransactions.length.toString().length > 6 && "text-[12px]"}`}>{allTransactions.length}</p>}
                        </div>
                      </div>
                      <h1 className=' font-semibold text-gray-500'>Transactions Count</h1>
                    </div>

                    <ReportPopup isOpen={isModalOpen} onClose={closeModal} fromDate={fromDate} toDate={toDate} userId={userId} />

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <ol className="text-left flex  rounded-lg">
              {
                institutions.map((each, index) => <li key={index} className="">
                  <button
                    onClick={() => {
                      setSelectInsId(each.insId)
                      setSelectInsName(each.insName)
                    }}
                    className={`border p-2 text-start font-normal text-sm rounded-md ${selectInsId === each.insId ? "bg-white text-gray-800 border-blue-700 border-2 " : ' bg-gray-100 border-gray-200 text-gray-900'}`}>
                    {each.insName}
                  </button>
                </li>)
              }
            </ol>
          </div>
          <div className=" max-h-[60vh] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-800 scrollbar-track-gray-300">
            <table className="min-w-full  bg-white text-sm text-left">
              <thead className='border text-gray-800'>
                <tr>
                  <th className="  p-2">Account Name</th>
                  <th className="  p-2">Official Name</th>
                  <th className="  p-2">Mask</th>
                  <th className="  p-2">Account Type</th>
                  <th className="  p-2">Account Sub Type</th>
                  <th className="  p-2">Available Balance</th>
                  <th className="  p-2">Current Balance</th>
                  <th className="  p-2">Transaction</th>
                  <th className="  p-2">Action</th>
                </tr>
              </thead>
              <tbody className=''>
                {currentData.map((each, index) => (
                  <tr key={index} className='text-gray-600 border' >
                    <td className="py-2 px-2">{each.accountName !== null ? each.accountName : "N/A"}</td>
                    <td className=" py-2 px-2">{each.officialName !== null ? each.officialName : "N/A"}</td>
                    <td className=" py-2 px-2">(XXXX <span>{each.mask !== null ? each.mask : "N/A"})</span></td>
                    <td className="py-2 px-2">{each.type !== null ? each.type : "N/A"}</td>
                    <td className="py-2 px-2">{each.subtype !== null ? each.subtype : "N/A"}</td>
                    <td className="py-2 px-2">${each.availableBalance !== null ? each.availableBalance : "N/A"}</td>
                    <td className="py-2 px-2">${each.currentBalance !== null ? each.currentBalance : "N/A"}</td>
                    <td className="py-2 px-2">
                      <div className="flex space-x-0.5">
                        <button onClick={() => {
                          setSpinning(index)
                          syncTransactions(each.accountId, each.accessToken, each.institutionId)
                        }} className={`bg-[#0832DE] text-white rounded-lg border `}>
                          {spinning === index ? <p className="animate-spin p-1"><IoSyncSharp /></p> :
                            <p className="m-1 px-2 text-sm ">Sync</p>}

                        </button>
                        <button onClick={() => {
                          navigate(`/account-transactions/${each.accountId}`)
                        }} className={`text-[#0832DE] rounded-lg border border-[#0832DE]`}>
                          <p className="m-1 px-2 text-sm ">View</p>

                        </button>
                      </div></td>
                    <td className="py-2 px-2">
                      <button onClick={() => {
                        setBlinking(index)
                        reAuthAccounts(each.accountId)
                        // syncTransactions(each.accountId, each.accessToken, each.institutionId)
                      }} className={`bg-[#0832DE] text-white rounded-lg border `}>
                        {blinking === index ? <p className=" m-1 px-2 text-lg animate-blink"><SiAuthy /></p> :
                          <p className="m-1 px-2 text-lg "><SiAuthy /></p>
                        }

                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex justify-between items-center  border-l border-r border-b p-2">
            <button
              className={`rounded-lg bg-[#ffffff] border border-gray-400 text-gray-800 m-1 p-1 text-sm ${currentPage === 1 ? 'cursor-not-allowed' : ' focus:outline-none focus:shadow-outline'}`}
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className='text-gray-800 text-sm'>Page {currentPage} of {totalPages}</span>
            <button
              className={`rounded-lg bg-[#ffffff] border border-gray-400 text-gray-800 m-1 p-1 text-sm ${currentPage === totalPages ? 'cursor-not-allowed' : 'focus:outline-none focus:shadow-outline'}`}
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      }
    </div>
  )
}
export default BanksAndAccountsContentArea;