import React, { useState, useEffect } from "react";
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from "axios";
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Signindesign from './../assets/Signindesign.png'
import { MdLockOutline } from "react-icons/md";
import { MdOutlineEmail } from "react-icons/md";

const Home = () => {
  const [loader, setLoader] = useState(false)
  const navigate = useNavigate();
  console.log(process.env.REACT_APP_API_URL)
  const token = Cookies.get('k9_jwt_token')


  useEffect(() => {
    // If the user is authenticated, redirect them to the dashboard
    if (token) {
      navigate('/dashboard');
    }
  }, [token, navigate]);

  const initialValues = {
    email: '',
    password: '',
  };

  const validationSchema = Yup.object({
    email: Yup.string().required('Email is required').email('Invalid email address'),
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .matches(/[a-z]/, 'Password must contain a lowercase letter')
      .matches(/[A-Z]/, 'Password must contain an uppercase letter')
      .matches(/[0-9]/, 'Password must contain a number')
      .matches(/[@$!%*?&#]/, 'Password must contain a special character'),
  });

  const login = async (values) => {
    try {
      setLoader(true)
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/users/super-admin-login`,
        values, { withCredentials: true }
      );
      if (response.data.success) {
        Cookies.set('k9_jwt_token', response.data.data.token, { expires: 30, secure: true });
        localStorage.setItem("k9role", response.data.data.userRole)
        toast.success(response.data.message)
        setTimeout(() => {
          navigate('/dashboard');
          setLoader(false);
        }, 2000)
      }
    } catch (error) {
      console.error("Error loging in:", error);
      toast.error(error.response.data.message)
      setLoader(false);
    }
  };

  const handleSubmit = (values) => {
    login(values)
  };


  return (
    <div className="h-[100vh] overflow-y-auto flex">
      <ToastContainer />
      <div className="w-[50vw] h-[100vh] bg-[#f0f5fc]">
        <div className="flex justify-center items-center h-full">
          <div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <h1 className="text-2xl font-semibold mb-6">LOGIN</h1>
                <div className="mb-3 h-[70px]">
                  <div className="border bg-white rounded-lg bg-gray-100 flex justify-center items-center">
                    <p className="text-xl py-2 px-2"><MdOutlineEmail /></p>
                    <Field
                      type="text"
                      name="email"
                      placeholder="Email"
                      className="w-full bg-white px-2 py-2 focus:outline-none"
                    />
                  </div>
                  <ErrorMessage name="email" component="div" className="text-left ml-2 text-red-500 text-sm mt-1" />
                </div>

                <div className="mb-3 h-[70px]">
                  <div className="border bg-white rounded-lg bg-gray-100 flex justify-center items-center">
                    <p className="text-xl py-2 px-2"><MdLockOutline /></p>
                    <Field
                      type="password"
                      name="password"
                      placeholder="Password"
                      className="w-full  px-2 py-2 bg-white focus:outline-none "
                    />
                  </div>
                  <ErrorMessage name="password" component="div" className="text-left ml-2 text-red-500 text-sm mt-1" />
                </div>

                <div className="">
                  <button
                    type="submit"
                    className="px-3 py-2 text-white bg-[#000AFF] rounded-lg hover:bg-[#0209cf] focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    {loader ? (
                      <div className="flex items-center">
                        <div
                          className="spinner"
                          style={{
                            border: '2px solid rgba(255, 255, 255, 0.3)',
                            borderRadius: '50%',
                            borderTop: '2px solid #fff',
                            width: '20px',
                            height: '20px',
                            animation: 'spin 1s linear infinite',
                            marginRight: '10px',
                          }}
                        />
                        Verifying...
                      </div>
                    ) : (
                      'Login'
                    )}
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
      <div className="">
        <img src={Signindesign} alt="signindesign" className="w-[50vw] h-[100vh]" />
      </div>
    </div>
  )
}

export default Home;

